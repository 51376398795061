import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { NextUIProvider } from "@nextui-org/react";
import "@Styles/tailwind.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@Styles/index.css";

Sentry.init({
  dsn: "https://cf3119781f5823d04b47081c7b9f41e1@o4506961333846016.ingest.us.sentry.io/4506961350885376",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["dev.app.tastyplan.rocks", "app.tastyplan.rocks"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: false,
  enabled: process.env.NODE_ENV !== "development",
});

// Suppresses aria warnings...
const originalWarn = console.warn;
console.warn = (...args) => {
  const [firstArg] = args;
  if (
    typeof firstArg === "string" &&
    firstArg.includes(
      "An aria-label or aria-labelledby prop is required for accessibility."
    )
  ) {
    return;
  }

  originalWarn(...args);
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <>
    <Router>
      <NextUIProvider locale="en-GB">
        <App />
      </NextUIProvider>
    </Router>
  </>
);
