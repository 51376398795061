import { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "@Contexts/AuthContext";
import { DataProvider } from "@Contexts/DataContext";
import SideBar from "@Components/SideBar";

const Login = lazy(() => import("@Pages/login"));
const SignUp = lazy(() => import("@Pages/signup"));
const Onboarding = lazy(() => import("@Pages/onboarding"));
const Home = lazy(() => import("@Pages/index"));
const Dashboard = lazy(() => import("@Pages/dashboard"));

import "@Utility/index";
import { PopupProvider } from "@Contexts/PopupContext";

import CompanyLogo from "@Assets/logo.png";
import { useTheme } from "@nextui-org/use-theme";
import NotFoundPage from "./pages/not-found";
import { Toaster } from "sonner";

export default function App() {
  const [expanded, setExpanded] = useState(false);
  const { theme } = useTheme();

  return (
    <>
      <div className={`${theme} overflow-y-hidden h-screen w-screen`}>
        <AuthProvider>
          <DataProvider>
            <PopupProvider>
              <Suspense
                fallback={
                  <FallBack expanded={expanded} setExpanded={setExpanded} />
                }
              >
                {/* <NavigationBar /> */}
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route
                    path="/dashboard/*"
                    element={
                      <Dashboard
                        expanded={expanded}
                        setExpanded={setExpanded}
                      />
                    }
                  />
                  <Route path="/not-found" element={<NotFoundPage />} />
                  <Route path="/*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </PopupProvider>
          </DataProvider>
        </AuthProvider>
      </div>
      <Toaster
        richColors
        theme={theme as any}
        closeButton
        duration={4000}
        gap={12}
        visibleToasts={5}
      />
    </>
  );
}

const FallBack = ({ expanded, setExpanded }: any) => {
  return (
    <div className="flex dark:bg-black">
      <SideBar expanded={expanded} setExpanded={setExpanded} />
      <div className="w-full py-4 px-5 transition-all h-screen grid place-items-center">
        <img src={CompanyLogo} width={30} />
      </div>
    </div>
  );
};
