import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@nextui-org/react";
import React from "react";

import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <h1 className="text-8xl font-bold italic text-red-400">404</h1>
        <h1 className="text-xl">
          OOPS... WE CAN'T SEEM TO FIND THE PAGE YOU ARE LOOKING FOR 😔
        </h1>
        <Button
          onPress={() => navigate(user ? "/dashboard" : "/")}
          style={{ marginTop: "20px", padding: "10px 20px", fontSize: "16px" }}
        >
          Go to {user ? "Dashboard" : "Home"}
        </Button>
      </div>
    </>
  );
};

export default NotFoundPage;
