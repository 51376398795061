import React, { createContext, useContext, useEffect, useMemo } from "react";
import { db } from "@Config/firebase";
import {
  and,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import PageLoading from "@Components/PageLoading";
import { useNavigate } from "react-router-dom";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { toast } from "sonner";

const DataContext = createContext<DataContextProps>({} as DataContextProps);

interface DataContextProps {
  organizations: CombinedOrganization[];
  getOrganization: (id: string) => CombinedOrganization;
  refresh: (
    update?:
      | "user"
      | "organizations"
      | "items"
      | "catalogs"
      | "suppliers"
      | "locations"
      | "warehouses"
      | "factories"
      | "lines"
      | "workstations"
      | "tasks"
  ) => Promise<void>;
  updateOrganization: (id: string, data: object) => Promise<any>;
  getSuppliers: (orgs: any) => Promise<{ [id: string]: Supplier[] }>;
  permit: (action: "owner" | "editor" | "viewer") => boolean | undefined;
  suppliers: Supplier[];
  items: Item[];
  catalogs: Catalog[];
  locations: Location[];
  warehouses: Warehouse[];
  organizationInvitations: Invitation[];
  userInvitations: Invitation[];
  locationInventory: { [id: string]: LocationInventoryItem[] };
  factories: Factory[];
  lines: { [id: string]: ProductionLine[] };
  workstations: { [id: string]: Workstation[] };
  tasks: { [id: string]: WorkTask[] };
  forecasts: { [id: string]: Forecast[] };
  warehouseInventory: { [id: string]: WarehouseInventoryItem[] };
  warehouseSchedule: { [id: string]: WarehouseEvent[] };
  warehouseOrders: { [id: string]: WarehouseOrder[] };
  locationOrders: { [id: string]: LocationOrder[] };
  organizationId: string;
  setOrganizationId: (v: string) => void;
  organization: CombinedOrganization | null;
  update: (res: AxiosResponse) => any;
  api: AxiosInstance;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useData() {
  return useContext(DataContext);
}
export function DataProvider({ children }: any) {
  const [organizations, setOrganizations] = React.useState<
    CombinedOrganization[]
  >([]);
  const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);
  const [catalogs, setCatalogs] = React.useState<Catalog[]>([]);
  const [items, setItems] = React.useState<Item[]>([]);
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [warehouses, setWarehouses] = React.useState<Warehouse[]>([]);
  const [locationInventory, setLocationInventory] = React.useState<{
    [id: string]: LocationInventoryItem[];
  }>({} as { [id: string]: LocationInventoryItem[] });
  const [forecasts, setForecasts] = React.useState<{
    [id: string]: Forecast[];
  }>({} as { [id: string]: Forecast[] });
  const [factories, setFactories] = React.useState<Factory[]>([]);
  const [lines, setLines] = React.useState<{
    [id: string]: ProductionLine[];
  }>({} as { [id: string]: ProductionLine[] });
  const [workstations, setWorkstations] = React.useState<{
    [id: string]: Workstation[];
  }>({} as { [id: string]: Workstation[] });
  const [tasks, setTasks] = React.useState<{
    [id: string]: WorkTask[];
  }>({} as { [id: string]: WorkTask[] });
  const [warehouseInventory, setWarehouseInventory] = React.useState<{
    [id: string]: WarehouseInventoryItem[];
  }>({} as { [id: string]: WarehouseInventoryItem[] });
  const [warehouseSchedule, setWarehouseSchedule] = React.useState<{
    [id: string]: WarehouseEvent[];
  }>({} as { [id: string]: WarehouseEvent[] });
  const [organizationInvitations, setOrganizationInvitations] = React.useState<
    Invitation[]
  >([]);
  const [userInvitations, setUserInvitations] = React.useState<Invitation[]>(
    []
  );
  const [warehouseOrders, _setWarehouseOrders] = React.useState<{
    [id: string]: WarehouseOrder[];
  }>({} as { [id: string]: WarehouseOrder[] });
  const [locationOrders, _setLocationOrders] = React.useState<{
    [id: string]: LocationOrder[];
  }>({} as { [id: string]: LocationOrder[] });
  // const [organization, setOrganization] = React.useState<
  //   CombinedOrganization | undefined
  // >();

  const [loadingText, setLoadingText] = React.useState("Updating Data...");

  const navigate = useNavigate();

  const [organizationId, setOrganizationId] = React.useState<string>(
    localStorage.getItem("organization_id") || organizations[0]?.id || ""
  );

  // @ts-expect-error
  const [refreshVal, setRefreshVal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  // const [salesLoading, setSalesLoading] = React.useState(false);

  const { user, setUser } = useAuth();

  const api = useMemo(() => {
    let instance = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/stacks/retail/data`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    let loading: string | number;

    instance.interceptors.request.use((request) => {
      if (request.toast !== false) {
        loading = toast.loading("Processing...", {
          description: "Awaiting response from the server.",
        });
      }
      return request;
    });

    instance.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          update(response);
          response.config?.toast !== false &&
            toast.success("Success!", {
              id: loading,
              description: response.config.toast || "Operation was successful.",
            });
        }
        return response;
      }, // Pass successful responses
      (error) => {
        toast.error("Oops!", {
          id: loading,
          description:
            error?.response?.data?.message?.length > 20
              ? "An error occurred while processing your request."
              : error?.response?.data?.message,
        });
        console.error(error?.response?.data?.message || error?.message);
        if (error.response && error?.response?.data?.message) {
          return Promise.reject(new Error(error.response.data.message));
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [user]);

  const organization = useMemo(() => {
    if (organizations.length) {
      const localOrganization = organizations.find(
        (o) => o.id === String(localStorage.getItem("organization_id"))
      );
      if (localOrganization?.id) {
        return localOrganization;
      } else {
        return organizations[0];
      }
    } else {
      return null;
    }
  }, [organizations]);

  async function getOrganizations() {
    setLoadingText("Getting Organizations...");

    const res = await api.get("/organizations", { toast: false });
    setOrganizations(res.data as unknown as CombinedOrganization[]);

    return res.data as unknown as CombinedOrganization[];
  }

  // const getWarehouseOrders = async (data: any) => {
  //   let allOrders: any = {};
  //   setLoadingText("Getting Warehouse Orders...");

  //   const orgsArray = data.orgs.map(
  //     async (organization: CombinedOrganization) => {
  //       data.wares[String(organization.id)].map(
  //         async (warehouse: Warehouse) => {
  //           const orders = (
  //             await getDocs(
  //               collection(
  //                 db,
  //                 `organizations/${organization.id}/warehouses/${warehouse.id}/orders`
  //               )
  //             )
  //           ).docs.map((orderDoc) => ({
  //             id: orderDoc.id,
  //             ...orderDoc.data(),
  //           }));
  //           allOrders[String(warehouse.id)] = orders;
  //           return orders;
  //         }
  //       );
  //     }
  //   );

  //   await Promise.all(orgsArray);

  //   setWarehouseOrders(allOrders);

  //   return allOrders;
  // };

  // const getLocationOrders = async (data: any) => {
  //   let allLocationOrders: any = {};
  //   setLoadingText("Getting Location Orders...");

  //   const orgsArray = data.orgs.map(
  //     async (organization: CombinedOrganization) => {
  //       data.locs[String(organization.id)].map(async (location: Location) => {
  //         const locOrders = (
  //           await getDocs(
  //             collection(
  //               db,
  //               `organizations/${organization.id}/locations/${location.id}/orders`
  //             )
  //           )
  //         ).docs.map((orderDoc) => ({
  //           id: orderDoc.id,
  //           ...orderDoc.data(),
  //         }));

  //         allLocationOrders[String(location.id)] = locOrders;
  //         return locOrders;
  //       });
  //     }
  //   );

  //   await Promise.all(orgsArray);

  //   setLocationOrders(allLocationOrders);

  //   return allLocationOrders;
  // };

  const getOrganization = (id: string) => {
    return organizations.filter((org) => org.id === id)?.[0];
  };

  const updateOrganization = async (id: string, data: object) => {
    return await updateDoc(doc(db, `organizations/${id}`), data);
  };

  async function getSuppliers() {
    setLoadingText("Getting Suppliers...");

    const _suppliers = (
      await api.get(`/organizations/${organization?.id}/suppliers`, {
        toast: false,
      })
    ).data;
    setSuppliers(_suppliers);
    return _suppliers || [];
  }

  async function getCatalogs() {
    setLoadingText("Getting Catalogs...");

    const _catalogs = (
      await api.get(`/organizations/${organization?.id}/catalogs`, {
        toast: false,
      })
    ).data;
    setCatalogs(_catalogs);
    return _catalogs || [];
  }

  async function getItems() {
    setLoadingText("Getting Items...");

    const _items = (
      await api.get(`/organizations/${organization?.id}/items`, {
        toast: false,
      })
    ).data;
    setItems(_items);
    return _items || [];
  }

  async function getLocations() {
    setLoadingText("Getting Locations...");

    const _locations = (
      await api.get(`/organizations/${organization?.id}/locations`, {
        toast: false,
      })
    ).data;
    setLocations(_locations);
    return _locations || [];
  }

  async function getWarehouses() {
    setLoadingText("Getting Warehouses...");

    const _warehouses = (
      await api.get(`/organizations/${organization?.id}/warehouses`, {
        toast: false,
      })
    ).data;
    setWarehouses(_warehouses);
    return _warehouses || [];
  }

  async function getLocationInventory(_locations: Location[]) {
    setLoadingText("Getting Location Inventories...");

    let allInventory: any = {};

    const locsArray = _locations.map(async (location: Location) => {
      allInventory[String(location.id)] = (
        await api.get(
          `/organizations/${organization?.id}/locations/${location.id}/inventory`,
          { toast: false }
        )
      ).data;
    });

    await Promise.all(locsArray);

    setLocationInventory(allInventory);
    return allInventory;
  }

  async function getLocationForecasts(_locations: Location[]) {
    setLoadingText("Getting Location Forecasts...");

    let allForecasts: any = {};
    const startDate = new Date();

    const formatDate = (date: any) => date.toISOString().split("T")[0];

    const endDate = new Date();
    endDate.setDate(startDate.getDate() + 30);

    const locsArray = _locations.map(async (location: Location) => {
      allForecasts[String(location.id)] = (
        await api.get(
          `/organizations/${organization?.id}/locations/${location.id}/forecasts`,
          {
            params: {
              start_date: formatDate(new Date()),
              end_date: formatDate(
                new Date(new Date().setDate(new Date().getDate() + 30))
              ),
            },
            toast: false,
          }
        )
      ).data;
    });

    await Promise.all(locsArray);
    // console.log(allForecasts, "forcasts");

    setForecasts(allForecasts);
    return allForecasts;
  }

  async function getWarehouseInventory(_warehouses: Warehouse[]) {
    setLoadingText("Getting Location Inventories...");

    let allInventory: any = {};

    const locsArray = _warehouses.map(async (warehouse) => {
      allInventory[String(warehouse.id)] = (
        await api.get(
          `organizations/${organization?.id}/warehouses/${warehouse.id}/inventory`,
          { toast: false }
        )
      ).data;
    });

    await Promise.all(locsArray);

    setWarehouseInventory(allInventory);
    return allInventory;
  }

  const getWarehouseSchedule = async (data: any) => {
    const orgs = data.orgs;
    const wares = data.wares;

    let allInventory: any = {};
    setLoadingText("Getting Warehouse Schedule...");

    const orgsArray = orgs.map(async (organization: CombinedOrganization) => {
      const brndsArray = wares[String(organization.id)].map(
        async (ware: Warehouse) => {
          const wareinventory = (
            await getDocs(
              collection(
                db,
                `organizations/${organization.id}/warehouses/${ware.id}/schedule`
              )
            )
          ).docs.map((inventoryDoc) => ({
            id: inventoryDoc.id,
            ...inventoryDoc.data(),
          }));
          allInventory[String(ware.id)] = wareinventory;
          return wareinventory;
        }
      );
      return await Promise.all(brndsArray);
    });

    await Promise.all(orgsArray);

    setWarehouseSchedule(allInventory);
    return allInventory;
  };

  const getSales = async (data: any) => {
    const orgs = data.orgs;
    const locs = data.locs;

    setLoadingText("Getting Forecasts...");

    let demand: any = {};

    const orgsArray = orgs.map(async (organization: CombinedOrganization) => {
      const locsArray = locs[String(organization.id)].map(
        async (location: Location) => {
          if (organization.demand_type === "forecasts") {
            demand[String(location.id)] = (
              await getDocs(
                query(
                  collection(
                    db,
                    `organizations/${organization.id}/locations/${location.id}/forecast`
                  ),
                  and(
                    where("date", ">=", new Date()),
                    where(
                      "date",
                      "<=",
                      new Date(new Date().setDate(new Date().getDate() + 10))
                    )
                  )
                )
              )
            ).docs.map((inventoryDoc) => ({
              ...inventoryDoc.data(),
              id: inventoryDoc.id,
              date: inventoryDoc.data().date.toDate(),
            }));
          } else {
            demand[String(location.id)] = [];
          }
        }
      );
      return await Promise.all(locsArray);
    });

    await Promise.all(orgsArray);
    setForecasts(demand);
    demand = null;
    return demand;
  };

  // const getRemainingSales = async (data: any) => {
  //   const orgs = data.orgs;
  //   const brnds = data.brnds;
  //   const locs = data.locs;
  //   setLoadingText("Getting Sales...");
  //   setSalesLoading(true);

  //   const Module = await import("../wasm/pkg/wasm.js");
  //   await Module.default();

  //   let demand: any = {};

  //   const token = await getIdToken(user);

  //   const orgsArray = orgs.map(async (organization: CombinedOrganization) => {
  //     const brndsArray = brnds[String(organization.id)].map(
  //       async (brand: Brand) => {
  //         const locsArray = locs[String(brand.id)].map(
  //           async (location: Location) => {
  //             demand[String(location.id)] = (
  //               await Module.get_sales(
  //                 token,
  //                 `organizations/${organization.id}/brands/${brand.id}/locations/${location.id}`
  //               )
  //             ).map((s: any) => ({
  //               ...s,
  //               date: new Date(s.date),
  //               demand: Number(s.demand),
  //             }));
  //           }
  //         );
  //         return await Promise.all(locsArray);
  //       }
  //     );
  //     return await Promise.all(brndsArray);
  //   });

  //   await Promise.all(orgsArray);
  //   setForecasts(demand);
  //   setSalesLoading(false);
  //   demand = {};
  //   return demand;
  // };

  async function getFactories() {
    setLoadingText("Getting Factories...");

    const _factories = (
      await api.get(`/organizations/${organization?.id}/factories`, {
        toast: false,
      })
    ).data;
    setFactories(_factories);
    return _factories || [];
  }

  const getProductionLines = async (data: any) => {
    let allLines: any = [];

    setLoadingText("Getting Production Lines...");

    const orgLoop = data.orgs.map(async (org: CombinedOrganization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const line = (
          await getDocs(
            collection(db, `organizations/${org.id}/factories/${fact.id}/lines`)
          )
        ).docs.map((lineDoc) => ({ ...lineDoc.data(), id: lineDoc.id }));
        allLines[String(fact.id)] = line;
        return line;
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);
    setLines(allLines as { [id: string]: ProductionLine[] });
    return allLines;
  };

  const getWorkstations = async (data: any) => {
    let allWorkstation: any = [];
    setLoadingText("Getting Workstations...");

    const orgLoop = data.orgs.map(async (org: CombinedOrganization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const lineLoop = data.lines[String(fact.id)].map(
          async (line: ProductionLine) => {
            const workstat = (
              await getDocs(
                collection(
                  db,
                  `organizations/${org.id}/factories/${fact.id}/lines/${line.id}/workstations`
                )
              )
            ).docs.map((workDoc) => ({
              id: workDoc.id,
              ...workDoc.data(),
            }));
            allWorkstation[String(line.id)] = workstat;
            return workstat;
          }
        );
        await Promise.all(lineLoop);
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);
    setWorkstations(allWorkstation as { [id: string]: Workstation[] });
    return allWorkstation;
  };

  const getTasks = async (data: any) => {
    setLoadingText("Getting Tasks...");

    let allTasks: any = [];
    const orgLoop = data.orgs.map(async (org: CombinedOrganization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const lineLoop = data.lines[String(fact.id)].map(
          async (line: ProductionLine) => {
            const workStatLoop = data.workstats[String(line.id)].map(
              async (workstation: Workstation) => {
                const workstat = (
                  await getDocs(
                    collection(
                      db,
                      `organizations/${org.id}/factories/${fact.id}/lines/${line.id}/workstations/${workstation.id}/tasks`
                    )
                  )
                ).docs.map((workDoc) => ({
                  id: workDoc.id,
                  ...workDoc.data(),
                }));
                allTasks[String(workstation.id)] = workstat;
                return workstat;
              }
            );
            await Promise.all(workStatLoop);
          }
        );
        await Promise.all(lineLoop);
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);

    setTasks(allTasks as { [id: string]: WorkTask[] });
    return allTasks;
  };

  const getOrganizationInvitations = async () => {
    const _invitations = (
      await api.get(`/organizations/${organization?.id}/invitations`, {
        toast: false,
      })
    ).data;
    setOrganizationInvitations(_invitations);
    return _invitations || [];
  };

  const getUserInvitations = async () => {
    const _invitations = (
      await api.get(`/users/${user?.id}/invitations`, {
        toast: false,
      })
    ).data;
    setUserInvitations(_invitations);
    return _invitations || [];
  };

  useEffect(() => {
    (async () => {
      if (user?.email) {
        getOrganizations();
      } else {
        setLoading(false);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (organization?.id) {
        setLoading(true);
        const _locations = await getLocations();
        await getLocationInventory(_locations);
        await getLocationForecasts(_locations);
        const _warehouses = await getWarehouses();
        await getWarehouseInventory(_warehouses);
        await getItems();
        await getFactories();
        await getCatalogs();
        await getSuppliers();
        await getUserInvitations();
        await getOrganizationInvitations();
        setLoading(false);
      }
    })();
    // .finally(() => setLoading(false));
  }, [organization]);

  // const fetchAllData = () => {
  //   if (user?.email) {
  //     (async () => {
  //       setLoading(true);
  //       const orgs = await getOrganizations();
  //       const wares = await getWarehouses(orgs);
  //       const facts = await getFactories({ orgs });
  //       const lines = await getProductionLines({ orgs, facts });
  //       const workstats = await getWorkstations({ orgs, facts, lines });
  //       await getSuppliers(orgs);
  //       await getTasks({ orgs, facts, lines, workstats });
  //       await getLocationInventory({ orgs, locs });
  //       await getWarehouseInventory({ orgs, wares });
  //       await getWarehouseSchedule({ orgs, wares });
  //       await getSales({ orgs, locs });
  //       await getItems(orgs);
  //       await getCatalogs(orgs);
  //       await getWarehouseOrders({ orgs, wares });
  //       await getLocationOrders({ orgs, locs });
  //       setLoading(false);
  //       // getRemainingSales({ orgs, brnds, locs });
  //     })();
  //   } else setLoading(false);
  // };

  // useEffect(() => {
  //   fetchAllData();
  // }, [user]);

  useEffect(() => {
    if (!loading && user && !organizations?.length) {
      navigate("/onboarding");
    }
  }, [user, loading, organizations]);

  const update = (res: AxiosResponse) => {
    const setters: { [x: string]: React.Dispatch<React.SetStateAction<any>> } =
      {
        items: setItems,
        locations: setLocations,
        location_inventory: setLocationInventory,
        warehouses: setWarehouses,
        warehouse_inventory: setWarehouseInventory,
        catalogs: setCatalogs,
        users: setUser,
        organizations: setOrganizations,
        users_invitations: setUserInvitations,
        organization_invitations: setOrganizationInvitations,
        factories: setFactories,
        forecasts: setForecasts,
        suppliers: setSuppliers,
      };

    if (res.config.method === "post") {
      // Create

      const key = res.config.url
        ?.split("/")
        .reverse()[0] as keyof typeof setters;

      if ((key as any) === "invitations") {
        setters["organization_invitations"]((prev: any) => [...prev, res.data]);
        getUserInvitations();
        return;
      }
      if (res.config.url?.split("/").reverse()[1] === "forecasts") {
        const id = res.config.url?.split("/").reverse()[2];

        console.log(id, res.config.url?.split("/").reverse());

        if (!id) return;
        setters["forecasts"]((prev: any) => {
          return {
            ...prev,
            [id]: [...prev[id], res.data],
          };
        });
        return;
      }
      if (key === "bulk") {
        return;
      }
      if (key === "accept") {
        return;
      }

      setters[key]((prev: any) => [...prev, res.data]);
    }
    if (res.config.method === "delete") {
      // Delete
      const key = res.config.url
        ?.split("/")
        .reverse()[1] as keyof typeof setters;

      if ((key as any) === "invitations") {
        setters["organization_invitations"]((prev: Invitation[]) => [
          ...prev.filter(
            (item) =>
              item.invited_email !== res.config.url?.split("/").reverse()[0]
          ),
        ]);
        getUserInvitations();
        return;
      }
      if ((key as any) === "forecasts") {
        const id = res.config.url?.split("/").reverse()[2];
        if (!id) return;
        setters["forecasts"]((prev: any) => {
          return {
            ...prev,
            [id]: [
              ...prev[id].filter(
                (item: any) =>
                  item.item_id !== res.config.url?.split("/").reverse()[0]
              ),
            ],
          };
        });
        return;
      }
      setters[key]((prev: any[]) =>
        prev.filter(
          (item) => item.id !== res.config.url?.split("/").reverse()[0]
        )
      );
    }
    if (res.config.method === "put") {
      // Update
      const key = res.config.url
        ?.split("/")
        .reverse()[1] as keyof typeof setters;

      if (key === "users") {
        setters[key](res.data);
        return;
      }
      if ((key as any) === "inventory") {
        const id = res.config.url?.split("/").reverse()[2];
        if (!id) return;
        setters[
          res.config.url?.includes("locations")
            ? "location_inventory"
            : "warehouse_inventory"
        ]((prev: any) => {
          return {
            ...prev,
            [id]: [
              ...prev[id].filter(
                (item: any) =>
                  item.item_id !== res.config.url?.split("/").reverse()[0]
              ),
              res.data,
            ],
          };
        });
        return;
      }
      if ((key as any) === "forecasts") {
        const id = res.config.url?.split("/").reverse()[2];
        if (!id) return;
        setters["forecasts"]((prev: any) => {
          return {
            ...prev,
            [id]: [
              ...prev[id].filter(
                (item: any) =>
                  item.item_id !== res.config.url?.split("/").reverse()[0]
              ),
              res.data,
            ],
          };
        });
        return;
      }

      if ((key as any) === "invitations") {
        setters["organization_invitations"]((prev: Invitation[]) => [
          ...prev.filter(
            (item) =>
              item.invited_email !== res.config.url?.split("/").reverse()[0]
          ),
          res.data,
        ]);
        getUserInvitations();
        return;
      }
      setters[key]((prev: any[]) => [
        ...prev.filter(
          (item) => item.id !== res.config.url?.split("/").reverse()[0]
        ),
        res.data,
      ]);
    }
  };
  /**
   *
   * Used to refetch data from the database
   *
   * @param {string} [update]
   */
  const refresh = async (
    update?:
      | "user"
      | "organizations"
      | "items"
      | "catalogs"
      | "suppliers"
      | "locations"
      | "warehouses"
      | "factories"
      | "lines"
      | "workstations"
      | "tasks"
  ) => {
    switch (update) {
      case "user":
        // await getUserData();
        break;
      case "organizations": // This will refetch all the data without showing the loading screen
        await getOrganizations();
        break;
      case "items":
        await getItems();
        break;
      case "catalogs":
        await getCatalogs();
        break;
      case "suppliers":
        await getSuppliers();
        break;
      case "locations":
        const locs = await getLocations();
        await getLocationInventory(locs);
        await getSales({ orgs: organizations, locs });
        break;
      case "warehouses":
        const wares = await getWarehouses();
        await getWarehouseInventory(wares);
        await getWarehouseSchedule({ orgs: organizations, wares });
        break;
      case "factories":
        const getFactory = await getFactories();
        const getLine = await getProductionLines({
          orgs: organizations,
          facts: getFactory,
        });
        const getWorkstation = await getWorkstations({
          orgs: organizations,
          facts: getFactory,
          lines: getLine,
        });
        await getTasks({
          orgs: organizations,
          facts: getFactory,
          lines: getLine,
          workstats: getWorkstation,
        });
        break;
      case "lines":
        const lines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const workstats = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines,
          workstats,
        });
        break;
      case "workstations":
        const workLines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const workstation = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines: workLines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines: workLines,
          workstats: workstation,
        });
        break;
      case "tasks":
        const taskLines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const taskWorkstations = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines: taskLines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines: taskLines,
          workstats: taskWorkstations,
        });
        break;
      default: {
        // fetchAllData();
        setRefreshVal(Math.random());
      }
    }
    setRefreshVal(Math.random());
  };

  /**
   *
   * Checks if the action is permissible by the current user
   *
   * @param {("owner" | "editor" | "viewer")} action This is supposed to be the required "User Type" for the action, for example for deleting the user type must be admin.
   * @returns {*} if the action is permissible.
   */
  const permit = (action: "owner" | "editor" | "viewer") => {
    if (organization) {
      const member = organization?.members?.find(
        (member) => member.user_id === user?.id
      );

      if (!member) return false;

      if (action === "owner" && member.access === "owner") return true;
      if (action === "editor" && member.access === "owner") return true;
      if (action === "viewer" && member.access === "owner") return true;

      if (action === "editor" && member.access === "editor") return true;
      if (action === "viewer" && member.access === "editor") return true;

      if (action === "viewer" && member.access === "viewer") return true;
      return false;
    }
  };

  const value: DataContextProps = {
    organizations,
    getOrganization,
    refresh,
    updateOrganization,
    getSuppliers,
    permit,
    suppliers,
    items,
    catalogs,
    locations,
    warehouses,
    locationInventory,
    factories,
    lines,
    workstations,
    tasks,
    forecasts,
    warehouseInventory,
    warehouseSchedule,

    warehouseOrders,
    locationOrders,
    organizationId,
    setOrganizationId,
    organization,
    update,
    api,
    organizationInvitations,
    userInvitations,
  };

  return (
    <DataContext.Provider value={value}>
      {!loading ? children : <PageLoading text={loadingText} />}
    </DataContext.Provider>
  );
}
