import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoading from "@Components/PageLoading";
import { setUser as setSentryUser } from "@sentry/react";
import { useTheme } from "@nextui-org/use-theme";
import axios, { AxiosInstance } from "axios";

interface AuthContextProps {
  user: User | null;
  logout: () => Promise<void>;
  login: (req: { email: string; password: string }) => Promise<void>;
  createUser: (req: {
    name: string;
    username: string;
    email: string;
    password: string;
  }) => Promise<void>;
  api: AxiosInstance;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState(true);

  const api = useMemo(() => {
    return axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/stacks/retail/auth`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  async function createUser({
    name,
    username,
    email,
    password,
  }: {
    name: string;
    username: string;
    email: string;
    password: string;
  }) {
    const response = await api.post("/create-user", {
      email,
      password,
      name,
      username,
    });
    if (response.status === 200 && response.data?.token) {
      // cookies.set("token", response.data?.token, { path: "/" });
      setUser(response.data);
    } else {
      throw new Error(response.data.message);
    }
  }

  // async function continueWithGoogle() {
  //   await signInWithPopup(auth, googleProvider);
  //   if (!getUserData()) navigate("/onboarding");
  // }

  async function login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    const response = await api.post("/start-session", { email, password });
    if (response.status === 200 && response.data?.token) {
      // cookies.set("token", response.data?.token, { path: "/" });
      setUser(response.data);
    } else {
      throw new Error(response.data.message);
    }
  }

  async function logout() {
    // cookies.set("token", "", { path: "/" });
    localStorage.clear();
    sessionStorage.clear();
    await api.post("/end-session");
    setTheme("light");
    window.location.assign("/login");
    return;
  }

  useEffect(() => {
    (async () => {
      // const localToken = cookies.get("token");
      // if (!localToken) return setUser(null);
      // else {
      const response = await api.post("/restore-session");
      if (response.status === 200) {
        setUser(response.data);
        setSentryUser({
          username: response.data.username,
          email: response.data.email,
          id: response.data.id,
        });
      } else setUser(null);
      // }
    })().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const nonProtectedPaths = ["/login", "/signup", "/"];
    if (loading === false) {
      (async () => {
        if (user === null) {
          setTheme("light");
          if (!nonProtectedPaths.includes(location.pathname))
            navigate("/login");
        } else if (user?.phone_number && user.phone_number !== "null") {
          if (nonProtectedPaths.includes(location.pathname)) {
            navigate("/dashboard");
          } else {
            navigate(location.pathname);
          }
        } else {
          navigate("/onboarding");
        }
      })();
    }
  }, [user, loading]);

  const value: AuthContextProps = {
    user,
    logout,
    login,
    createUser,
    api,
    setUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <PageLoading text={"Logging In..."} />}
    </AuthContext.Provider>
  );
}
